import React from 'react';
import Loadable from 'react-loadable';
import {
  ComponentPreview,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import textPreviewData from '../../../../data/previews/text.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Text"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Type" tierThree="Text" />
      <V5Notice
        componentName="Text"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/typography-text--docs"
      />

      <PageNavigation links={['Size', 'Style', 'Microcopy', 'Platform']} />

      <Section title="Size">
        <Paragraph>
          The level of text you select should provide the appropriate hierarchy
          and visual coherency within the interface.
        </Paragraph>
        <ComponentPreview
          name="TextSize"
          layout="default"
          previewData={textPreviewData.levels}>
          <Text>
            If sending a check, please include the invoice number in the memo.
          </Text>
        </ComponentPreview>
      </Section>

      <Section title="Style">
        <Paragraph>
          The tight variation of text decreases the line height to save space.
          Because it is more difficult to read, tight is not intended for long
          blocks of copy.
        </Paragraph>
        <ComponentPreview
          name="TextStyle"
          layout="default"
          previewData={textPreviewData.styles}>
          <Text>
            If sending a check, please include the <br />
            invoice number in the memo.
          </Text>
        </ComponentPreview>
      </Section>

      <Section title="Microcopy">
        <Paragraph>
          The text component supports weight changes for emphasis. Be
          intentional when choosing to bring attention to information using
          italics or bold. There’s a hard limit of{' '}
          <strong>one bold or italicized phrase</strong>
          —do not change the weight of a complete sentence.
        </Paragraph>
        <Paragraph>
          Use the{' '}
          <Link href="http://hudl.com/design/writing/writing-for/teams/product">
            non-label guidelines
          </Link>{' '}
          when typesetting text.
        </Paragraph>
      </Section>

      <Section title="Platform">
        <PlatformTable />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
